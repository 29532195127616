import React, { Component } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack';
import moment from "moment-timezone";
import Header from "../Header/Header";

export default class Advertisement extends Component {
    state = {
        campaignId: this.props.match.params.id,
        isLoading: true,
        showEmpty: false,
        campaignName: "",
        campaignUrl: "",
        campaignPaymentType: "",
        description: "",
        startDate: "",
        endDate: "",
        createdAt: "",
        imageUrl: "",
        type: ""
    }

    componentDidMount() {
        console.log("campaign-id", this.state.campaignId)
        this.getCampaignById();
    }
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + `user/getCampaignByIdWeb/${this.props.match.params.id}`)
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success!!");
                    if (response.data.campaign.length == 0) {
                        self.setState({
                            isLoading: false,
                            showEmpty: true
                        })
                    } else {
                        self.setState({
                            campaignName: response.data.campaign.campaignName,
                            campaignPaymentType: response.data.campaign.campaignPaymentType,
                            campaignUrl: response.data.campaign.campaignUrl,
                            description: response.data.campaign.description,
                            startDate: response.data.campaign.startDate,
                            endDate: response.data.campaign.endDate,
                            createdAt: response.data.campaign.createdAt,
                            imageUrl: response.data.campaign.imageUrl,
                            type: response.data.campaign.type,
                            isLoading: false
                        })
                    }
                }
                else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    onClickUrl = () => {
        window.open(this.state.campaignUrl)
    }
    render() {

        const { isLoading, campaignName, showEmpty, campaignUrl, campaignPaymentType, description, imageUrl, startDate, createdAt, endDate } = this.state
        if (isLoading) {
            return <div>{console.log("loading")}</div>
        }
 
        if (showEmpty) {
            return (
                <div>
                    <div >
                        <Header /><br />
                        <div className="shared-adv">
                            <center className="expired-t"><b>Advertisement Expired </b></center>
                            <center className="mt-3 expired-t"><b>Thanks for your interest</b></center>
                        </div>
                        <br />
                    </div>
                </div>
            )
        }
        return (

            <div >
                <Header />
                <div className="enable-center mx-4">
                    <div>
                        <center><img src={imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + imageUrl : imageUrl} className="full-adv" style={{ marginTop: "5rem" }} /></center>
                        <div className="campaign-name">{campaignName}</div>
                        <p className="mt-2 adv-sm">{description}</p>
                        <p className="adv-sm">Start Date: {startDate.split("T")[0].split("-").reverse().join("-")}</p>
                        <p className="adv-sm">End Date: {endDate.split("T")[0].split("-").reverse().join("-")}</p>
                        <div className="campaign-url" style={{ wordWrap: "break-word" }}>Url: <span onClick={this.onClickUrl}>{campaignUrl}</span></div>
                    </div>
                </div>
            </div >
            
        )
    }
}

