import React, { useState, useEffect } from 'react'
import { Button, Form, Col, Row, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Redirect } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import TermsAndConditions from '../Terms/Terms';
import PrivacyPolicy from '../Terms/PrivacyPolicy';
import VerifyOtpPopup from './VerifyOtpPopup';
import AppLinkPopup from './AppLinkPopup';
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex = /^[a-z0-9+_.-]+@[a-z0-9.-]+$/;
const validPasswordRegex = /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;

export default function Popup({ showSignup, closePopup }) {
    const [referral, setReferral] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [registerFlow, setRegisterFlow] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [verifyEmailPage, setVerifyEmailPage] = useState(false);
    const [loginPage, setLoginPage] = useState(false);
    const [checked, setChecked] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [showTerms, setShowTerms] = useState(false)
    const [showPrivacy, setShowPrivacy] = useState(false)
    const [showVerifyOtp, setShowVerifyOtp] = useState(false)
    const [redirect, setRedirect] = useState(false);
    const [touchedEmail, setTouchedEmail] = useState(false);
    const handleCheck = () => {
        setChecked(!checked);
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        if (name === "referral") setReferral(value);
        else if (name === "name") setName(value);
        else if (name === "email") setEmail(value);
        else if (name === "password") setPassword(value);
        else if (name === "confirmPassword") setConfirmPassword(value);
    }

    const handleBlur = (event) => {
        const { name } = event.target;
        if (name === "email") setTouchedEmail(true);
        validateField(name);
    }
    const displayTerms = () => {
        setShowTerms(!showTerms)
    }
    const displayPrivacy = () => {
        setShowPrivacy(!showPrivacy)
    }
    const toggleVerifyOtp = () => {
        setLoginPage(!loginPage)
        closePopup()
    }
    const closeRedirect = () => {
        setRedirect(!redirect)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('name', JSON.stringify(name));
        const formFields = ["name", "email", "password", "confirmPassword"];
        let isValid = true;
        formFields.forEach((field) => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the details");
            document.getElementById('status').innerHTML = "<div>Please enter valid details</div>";
        } else {
            postDetails();
        }
    }

    const checkEmail = () => {
        const headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        };
        axios.post(config.apiUrl + 'auth/checkEmailStatus', { "email": email }, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>';
                    setEmailError("");
                    setErrMsg(true);
                    document.getElementById('error-msg').innerText = "";
                    document.getElementById('false').innerHTML = '';
                } else {
                    if (response.data.message === "Email not exist") {
                        setErrMsg(false);
                        setEmailError("Please enter email");
                    } else {
                        setErrMsg(false);
                        setEmailError("Email already registered!");
                    }
                    document.getElementById('false').innerHTML = '<i class="fa fa-times" id="false" aria-hidden="true"></i>';
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const postDetails = () => {
        const headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        };

        const encryptedPassword = simpleCrypto.encrypt(password);
        const encryptedConfirmPassword = simpleCrypto.encrypt(confirmPassword);

        axios.post(config.apiUrl + 'auth/register', {
            "name": name,
            "email": email,
            "password": encryptedPassword,
            "confirmPassword": encryptedConfirmPassword,
            "registerFlow": registerFlow
        }, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    sendOtp();
                    setLoginPage(!loginPage);
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById('error').innerText = error;
            });
    }

    const sendOtp = () => {
        const headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        };

        axios.post(config.apiUrl + 'auth/sendOtp', {
            "email": email,
            "registerFlow": registerFlow
        }, { headers: headers })
            .then((response) => {
                const msg = "Otp sent successfully!!";
                if (response.data.status === true) {
                    document.getElementById('otp').innerText = msg;
                } else {
                    console.log(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const validateField = (name) => {
        let isValid = false;
        if (name === "name") isValid = validateName();
        else if (name === "email") isValid = validateEmailAddress();
        else if (name === "password") isValid = validatePassword();
        else if (name === "confirmPassword") isValid = validateConfirmPassword();
        return isValid;
    }

    const validateName = () => {
        let nameError = "";
        const value = name;
        if (value.trim() === "") nameError = "Name must be at least 5 characters long!";
        else if (value.length < 5)
            nameError = 'Name must be at least 5 characters long!';
        else if (value.length > 50)
            nameError = 'Name should not be more than 50 characters long!';
        setNameError(nameError);
        return nameError === "";
    }

    const validateEmailAddress = () => {
        let emailError = "";
        const value = email.trim();
        if (value === "") emailError = "Email is required";
        else if (!validEmailRegex.test(value))
            emailError = "Please enter a valid email";
        setEmailError(emailError);
        if (!emailError) {
            checkEmail();
        }
        return emailError === "";
    }

    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim() === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError);
        return passwordError === "";
    }

    const validateConfirmPassword = () => {
        let confirmPasswordError = "";
        if (password !== confirmPassword)
            confirmPasswordError = "Password does not match";
        setConfirmPasswordError(confirmPasswordError);
        return confirmPasswordError === "";
    }

    const showPasswordFn = () => {
        setShowPassword(!showPassword);
    }

    const showConfirmPasswordFn = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    if (verifyEmailPage) {
        return <Redirect
            to={{
                pathname: "/verify",
                state: { email, registerFlow, name }
            }} />
    }

    // if (loginPage) {
    //     return <Redirect
    //         to={{
    //             pathname: "/verifyotp",
    //             state: { email, registerFlow, name }
    //         }} />
    // }
    return (
        <div>
            {/* {showTerms ?
                <TermsAndConditions terms={showTerms} displayTerms={displayTerms} /> :
                showPrivacy ?
            //         <PrivacyPolicy privacy={showPrivacy} displayPrivacy={displayPrivacy} /> : */}
            {/*  {redirect && <AppLinkPopup redirect={redirect} closeRedirect={closeRedirect} />} */}
            {loginPage && <VerifyOtpPopup email={email} setRedirect={setRedirect} registerFlow={registerFlow} name={name} loginPage={loginPage} togglePopup={toggleVerifyOtp} />}

            <Modal show={showSignup} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} className="form-signup" style={{ fontFamily: "Open Sans" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="signup-text">Sign up for&nbsp;<span className="_sceem">Sceem</span></div>
                            <div> <i onClick={closePopup} className="mt-2 cursor-pointer fa fa-times" aria-hidden="true"></i></div>
                        </div>
                        <center className="mt-2">
                            <Row style={{ width: "100%" }}>
                                <Col xs={12} md={12}>
                                    <div style={{ width: "90%" }} >
                                        <div className="login-credentials">Register</div><br />
                                        <div className="box-form">
                                            <div className="form-group mt-2">
                                                <input
                                                    type="email"
                                                    className="form-control text-lowercase form-bg"
                                                    aria-describedby="text"
                                                    value={email}
                                                    name="email"
                                                    placeholder="Email*"
                                                    onChange={onChange}
                                                    onBlur={handleBlur}
                                                />
                                                {/* <span id="true" className="showPassword tick-color-green"></span>
                                                <span id="false" className="tick-color-red showPassword"></span>
                                                <div className="signup-error-top div">{emailError && (
                                                    <div className="error">{emailError}</div>
                                                )}</div>
                                                <div id="error-msg" className="div error"></div> */}
                                                {touchedEmail && !emailError && ( // Only render tick or cross if email has been touched and there's no email error
                                                    <span className="tick-color-green showPassword"><i className="fa fa-check" aria-hidden="true"></i></span>
                                                )}
                                                {touchedEmail && emailError && ( // Only render tick or cross if email has been touched and there's an email error
                                                    <span className="tick-color-red showPassword"><i className="fa fa-times" aria-hidden="true"></i></span>
                                                )}
                                                <div className="signup-error-top div">
                                                    {emailError && (
                                                        <div className="error">{emailError}</div>
                                                    )}
                                                </div>
                                                <div id="error-msg" className="div error"></div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    className="form-control form-bg"
                                                    value={password}
                                                    disabled={touchedEmail && !emailError ? false : true}
                                                    placeholder="Password*"
                                                    onChange={onChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span className="showPassword" onClick={showPasswordFn}>
                                                    {showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                                </span>
                                                <div className="signup-error-top div">{passwordError && (
                                                    <div className="error">{passwordError}</div>
                                                )}</div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    name="confirmPassword"
                                                    className="form-control form-bg"
                                                    value={confirmPassword}
                                                    placeholder="Confirm Password*"
                                                    disabled={touchedEmail && !emailError ? false : true}
                                                    onChange={onChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span className="showPassword" onClick={showConfirmPasswordFn}>
                                                    {showConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                                </span>
                                                <div className="signup-error-top div">{confirmPasswordError && (
                                                    <div className="error">{confirmPasswordError}</div>
                                                )}</div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <input
                                                    type="text"
                                                    className="form-control form-bg"
                                                    aria-describedby="text"
                                                    value={name}
                                                    name="name"
                                                    placeholder="Name*"
                                                    disabled={touchedEmail && !emailError ? false : true}
                                                    onChange={onChange}
                                                    onBlur={handleBlur}
                                                />
                                                <div className="signup-error-top div">{nameError && (
                                                    <div className="error">{nameError}</div>
                                                )}</div>
                                            </div>
                                        </div>
                                        <div className="mt-2 signup-terms">
                                            <input type="checkbox" style={{ cursor: "pointer" }} checked={checked} onClick={handleCheck} />
                                            <span className="signup-agree-text">
                                                I agree to the&nbsp;
                                                <a href="https://www.sceem.org/terms" target="_blank" rel="noopener noreferrer">
                                                    <span className="signup-termslink-color" style={{ cursor: "pointer" }} onClick={displayTerms}>Terms & Conditions </span>
                                                </a>
                                                and&nbsp;
                                                <a href="https://www.sceem.org/privacypolicy" target="_blank" rel="noopener noreferrer">
                                                    <span className="signup-termslink-color" style={{ cursor: "pointer" }} >Privacy Policy</span>
                                                </a>
                                            </span>
                                        </div>
                                        <br />
                                        <div className="sign-b_Agree-Popup">
                                            <center>
                                                <div id="status" className="mb-1 errormsg"></div>
                                                <div id="error" className="mb-1 errormsg"></div>
                                                {(email !== "" && password !== "" && confirmPassword !== "" && name !== "") ?
                                                    <Button variant="default" type="submit" disabled={!checked} className="btn-block login-btn">Sign Up</Button> :
                                                    <Button variant="default" type="submit" disabled={true} className="btn-block login-btn">Sign Up</Button>}
                                                <br />
                                            </center>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </center>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* } */}
        </div>
    )
}
